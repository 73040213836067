const visibleClass = "Header--profile-menu-container--visible";

document.addEventListener("DOMContentLoaded", () => {
  let profileMenuContainer = document.querySelector(".js-profile-menu-container");
  if (!profileMenuContainer) return;

  profileMenuContainer.addEventListener("click", () => {
    profileMenuContainer.classList.toggle(visibleClass)
  });
});
