import UTILS from "lib/utils";
import $ from "jquery";
import KidAnalytics from 'lib/kid_analytics';

function seeCollectionOptions() {
  if (!window.isLoggedIn) {
    window.location = "/login";
    return;
  }

  let collectionOptions = getCollectionOptions();
  if (!collectionOptions) return;
  UTILS.modifyClasses(collectionOptions, "u-hidden", "remove");
}

function createCollection() {
  let collectionForm = getCollectionForm();
  let collectionOptions = getCollectionOptions();

  if (!collectionForm) return;
  UTILS.modifyClasses(collectionForm, "u-hidden", "remove");
  UTILS.modifyClasses(collectionOptions, "u-hidden", "add");
}

function editCollectionItemsMenuTogglebyClick() {

  $('.edit_collection').click(function(event) {

    let allCollectionItemEditMenus = document.querySelectorAll('.js-edit-collection-item-menu')

    if ($(event.target).hasClass('js-edit-collection-item-button')) {

      let el = event.target.parentNode;
      let collectionItemEditMenu = el.querySelector(".js-edit-collection-item-menu");

      // hide all the menus, then open the menu for button clicked
      UTILS.modifyClasses(allCollectionItemEditMenus, "u-hidden", "add");
      UTILS.modifyClasses(collectionItemEditMenu, "u-hidden", "remove");

    } else {
      // hide all menus on click outside of button
      UTILS.modifyClasses(allCollectionItemEditMenus, "u-hidden", "add");
    }
  });
}

function setupMakeCoverImageSuccessListener() {
  $('.js-make-cover-image').on('ajax:success', function() {
    location.reload(true);
  });
}

function setupCollectionCreationSuccessListener() {
  let collectionForm = getCollectionForm();
  let updateCollection = getUpdateCollectionLink();

  $(collectionForm).add(updateCollection).on('ajax:success', function() {
    KidAnalytics.trackCreateCollection();
    location.reload(true);
  });
}

function listenForCreateOrAddToCollectionResponse() {
  let addToCollectionButtons = document.querySelectorAll(".js-add-to-collection");
  let createCollectionButton = getCreateCollectionButton();

  addToCollectionButtons && addToCollectionButtons.forEach(button => {
    button.addEventListener("click", seeCollectionOptions)
  });

  createCollectionButton && createCollectionButton.addEventListener("click", function () {
    createCollection();
  });
}

function onOutsideClick() {
  let collectionOptions = getCollectionOptions();
  let outsideModal = document.querySelector('.js-outside-modal');

  collectionOptions && collectionOptions.addEventListener('click', function (event) {
    if (event.target == outsideModal) {
      UTILS.modifyClasses(collectionOptions, "u-hidden", "add");
    }
  });
}

function getCollectionForm() {
  return document.querySelector(".js-collection-form");
}

function getUpdateCollectionLink() {
  return document.querySelector(".js-modal-collection-options");
}

function getCreateCollectionButton() {
  return document.querySelector(".js-create-collection");
}

function getCollectionOptions() {
  return document.querySelector(".js-collection-options");
}

document.addEventListener("DOMContentLoaded", function () {
  listenForCreateOrAddToCollectionResponse();
  setupCollectionCreationSuccessListener();
  setupMakeCoverImageSuccessListener();
  onOutsideClick();
  editCollectionItemsMenuTogglebyClick();
});
