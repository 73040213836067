import AJAX from "lib/web_utils";
import KidAnalytics from "lib/kid_analytics";
import UTILS from "lib/utils";
import {jsonData} from "lib/json_data";

function setCartCount(size) {
  let cartCountElement = document.querySelector(".js-cart-count");
  if (cartCountElement) {
    cartCountElement.innerText = size;
  }
}

function refreshCartCount() {
  AJAX.get("/cart/size").then(function (response) {
    return response.json();
  }).then(function (cart) {
    setCartCount(cart.cart_size);
  });
}

function addToCartToViewCart() {
  let addToCartButton = getAddToCartButton();
  if (!addToCartButton) return;
  UTILS.modifyClasses(addToCartButton, "Button--primary", "remove");
  UTILS.modifyClasses(addToCartButton, "Button--primaryBorder", "add");
  UTILS.modifyClasses(addToCartButton, "js-in-cart", "add");
  addToCartButton.innerText = "View Cart";
}

function viewCartToAddToCart() {
  let addToCartButton = getAddToCartButton();
  if (!addToCartButton) return;
  UTILS.modifyClasses(addToCartButton, "Button--primary", "add");
  UTILS.modifyClasses(addToCartButton, "Button--primaryBorder", "remove");
  UTILS.modifyClasses(addToCartButton, "js-in-cart", "remove");
  addToCartButton.innerText = "Add To Cart";
}

function getAddToCartButton() {
  return document.querySelector(".js-add-to-cart");
}

function isInCart() {
  let addToCartButton = getAddToCartButton();
  return addToCartButton && addToCartButton.classList.contains("js-in-cart");
}

function redirectToLogin() {
  window.location = "/login";
}

function goToCart() {
  window.location = "/cart";
}

function attemptToAddItemToCart() {
  let itemData = jsonData('item');

  if (!itemData.id) return;

  // assume success
  addToCartToViewCart();
  KidAnalytics.trackAddListingToCart(itemData);

  // attempt to add item to cart
  AJAX.put("/cart/items/" + itemData.id).then(function (response) {
    if (response.status == 401) { // user is not logged in
      redirectToLogin();
    } else if (response.status == 200 || response.status == 201) {
      refreshCartCount();
    } else {
      viewCartToAddToCart();
    }
    return response.json();
  }).then(function (cart) {
    setCartCount(cart.size);
  }).catch(function (err) {
    viewCartToAddToCart();
  });
}

export function addToCartOnClick() {
  let addToCartButton = getAddToCartButton();
  addToCartButton && addToCartButton.addEventListener("click", function () {
    if (isInCart()) {
      goToCart();
    } else {
      attemptToAddItemToCart();
    }
  });
}

document.addEventListener("DOMContentLoaded", function () {
  addToCartOnClick();
});
