const HIDE_DRAWER_SESSION_KEY = "hideDownloadAppDrawer"
const SHOW_CSS_CLASS = "drawer--open"

export default class AppDrawer {
  constructor(deepLink) {
    this.appDrawer = document.getElementById("download-app-drawer");

    if (!this.appDrawer) return

    if (this._is_disabled()) {
      this._disable()
      return
    }

    this._initCloseButton()
    this._initCta(deepLink)
  }

  _initCloseButton() {
    let closeButton = this.appDrawer.querySelector(".drawer__close")

    if (!closeButton) return

    let self = this
    closeButton.addEventListener("click", () => self.hide())
  }

  _initCta(deeplink) {
    let cta = this.appDrawer.querySelector(".Button")
    if (!cta) return
    cta.href = deeplink
  }

  show() {
    if (window.innerWidth >= 768) return
    if (!this.appDrawer) return
    if (this._is_disabled()) return

    this.appDrawer.classList.add(SHOW_CSS_CLASS)
  }

  _is_disabled() {
    // check session storage if we shouldn't show this prompt any more
    let hasDismissedPrompt = window.sessionStorage && window.sessionStorage.getItem(HIDE_DRAWER_SESSION_KEY)
    if (hasDismissedPrompt) return true

    return false
  }

  hide() {
    this.appDrawer.classList.remove(SHOW_CSS_CLASS)
    this._disable()
  }

  _disable() {
    // persist to session storage, which is cleared when they close the tab
    if (window.sessionStorage) window.sessionStorage.setItem(HIDE_DRAWER_SESSION_KEY, true)

    if (!this.appDrawer) return
    this.appDrawer.classList.add("drawer--disabled")
  }
}
