export default function Form(element) {
    this.element = element;
    this.submitButton = this.element.querySelector('input[type="submit"]');

    this._checkFormValidity();
    this._initializeFormValidityListeners();
}

Form.prototype._initializeFormValidityListeners = function() {
    var self = this;

    [].forEach.call(this.element.querySelectorAll('input'), function(element) {
        element.addEventListener('keyup', self._checkFormValidity.bind(self));
    });

    [].forEach.call(this.element.querySelectorAll('select'), function(element) {
        element.addEventListener('change', self._checkFormValidity.bind(self));
    });
};

Form.prototype._checkFormValidity = function() {
    this.submitButton.disabled = !this.element.checkValidity();
};
