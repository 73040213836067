import UTILS from "lib/utils";
import KidAnalytics from 'lib/kid_analytics';

/*
 * @description - Thumbnail clicking functionality that changes main image
 */
function changeItemDetailsImageOnClick() {


  let mainImageContainer = document.querySelector(".js-item-detail-main-image"),
    thumbnailImageContainers = document.querySelectorAll(".js-item-detail-thumbnail-container");

  [].forEach.call(thumbnailImageContainers, function (element) {

    // Add a click event to each image
    element.addEventListener("click", function (event) {

      event.preventDefault();

      // Only if it's not loading.
      if (!UTILS.hasClass(element, "isLoading") && !UTILS.hasClass(mainImageContainer, "isLoading")) {
        let thumbnailImage = element.getElementsByTagName('img')[0],
          mainImage = mainImageContainer.getElementsByTagName('img')[0];

        UTILS.updateImageSource(mainImage, thumbnailImage.src);

        // Add the active state to the right thumbnail image.
        UTILS.modifyClasses(thumbnailImageContainers, "is-active", "remove");
        UTILS.modifyClasses(element, "is-active", "add");

      }

    });

  });

}

/*
 * @description: Global site search */
function siteSearch() {
  let searchForm = document.querySelectorAll(".js-search");

  if (!(searchForm)) return;

  if (!(!!searchForm)) return;

  Array.prototype.forEach.call(searchForm, function (individualSearchForm) {

    individualSearchForm.addEventListener("submit", function (event) {
      event.preventDefault();

      let searchInput = individualSearchForm.querySelector(".js-search-input"),
        searchAllItems = individualSearchForm.dataset.searchAllItems === "true";

      if (searchInput && searchInput.value && searchInput.value.length >= 1) {
        KidAnalytics.trackSearch();

        if (searchAllItems) {
          window.location.href = UTILS.updateQueryString("text", encodeURIComponent(searchInput.value), window.location.protocol + "//" + window.location.host + "/items");
        } else {
          let newURL = UTILS.updateQueryString("page", 1);
          window.location.href = UTILS.updateQueryString("text", encodeURIComponent(searchInput.value), newURL);
        }
      }

    });

  });

}

function lazilyLoadImages() {

  let lazyImages = document.querySelectorAll(".js-lazy-load");

  Array.prototype.forEach.call(lazyImages, function (lazyImage) {

    let imageSource = lazyImage.getAttribute("data-img-src"),
      imageTitle = lazyImage.getAttribute("data-img-title"),
      classToAffect = lazyImage.getAttribute("data-class-to-affect"),
      elToAffect = UTILS.getClosest(lazyImage, classToAffect) || lazyImage,
      image = new Image();

    image.addEventListener('load', function (event) {
      lazyImage.appendChild(image);
      lazyImage.style.width = null;
      lazyImage.style.height = null;

      if (elToAffect) {
        UTILS.modifyClasses(elToAffect, "isLoading", "remove");
      }

      // Don't set alt attribute until the image has loaded to avoid text appearing.
      if (imageTitle && imageTitle !== "") {
        image.setAttribute("alt", imageTitle);
      }
    });

    image.src = imageSource;
  });

}

function openAndCloseFilters() {

  let filterSections = document.querySelectorAll(".js-filter-section"),
    filterSectionHeaders = document.querySelectorAll(".js-filter-section-header");

  if (filterSectionHeaders) {

    filterSectionHeaders.forEach(function (filterSectionHeader, i) {
      filterSectionHeader.addEventListener("click", function () {
        let filterSection = filterSections[i];
        filterSection.classList.toggle("isClosed");
      });
    });

  }
}

/*
 * @description - When the search button is clicked on mobile, it hides the logo, shows the form and focuses on the input
 */
function toggleSearch() {
  const searchButton = document.querySelector(".js-searchbutton"),
    searchContainer = document.querySelector(".js-search-container"),
    searchInput = document.querySelector(".js-search-input"),
    searchX = document.querySelector(".js-search-x"),
    searchVisibilityClass = "Header-search-container--search-visible";

  if (searchButton) {
    searchButton.addEventListener("click", function () {
      searchContainer.classList.toggle(searchVisibilityClass);

      setHeaderPosition();

      // focus on the Input if the input is now visible.
      if (searchContainer.classList.contains(searchVisibilityClass)) {
        searchInput.focus();
        searchButton.ariaExpanded = true;
      } else {
        searchButton.ariaExpanded = false;
      }
    });
  }

  if (searchX) {
    searchX.addEventListener("click", function () {
      searchContainer.classList.remove(searchVisibilityClass);
      searchButton.ariaExpanded = false;

      setHeaderPosition();

      // unfocus the Input
      searchInput.blur();
    });
  }
}

function openAndCloseMenu() {
  let menuButton = document.querySelector(".js-menu-button"),
    menu = document.querySelector(".js-navigation");

  if (!menuButton) return;

  menuButton.addEventListener('click', () => {
    window.scrollTo(0, 0);

    document.body.classList.toggle("Oy(H)")

    setHeaderPosition();

    menu.classList.toggle("Header-navigation--visible");
    menuButton.classList.toggle("Header-hamburger-cell--open");
  });
}

function setHeaderPosition() {
  let headerHeight = UTILS.getAbsoluteHeight(document.querySelector("header")),
    navigationEl = document.querySelector(".js-navigation");

  navigationEl.style.top = (headerHeight + 1) + "px"; // Adding 1 to make up for the Splitter being there.
}

function scollElementsToBottom() {
  let elements = document.querySelectorAll(".js-scroll-to-bottom");

  Array.prototype.forEach.call(elements, function (element) {
    element.scrollTo(0, element.scrollHeight);
  });
}

function autoSizeTextArea(textareaElement) {
  // Reset field height
  textareaElement.style.height = "inherit";

  // Get the computed styles for the element
  let computed = window.getComputedStyle(textareaElement);

  // Calculate the height
  let height = parseInt(computed.getPropertyValue("border-top-width"), 10)
    + parseInt(computed.getPropertyValue("padding-top"), 10)
    + textareaElement.scrollHeight
    + parseInt(computed.getPropertyValue("padding-bottom"), 10)
    + parseInt(computed.getPropertyValue("border-bottom-width"), 10);

  let subtractors = parseInt(computed.getPropertyValue("padding-top"))
    + parseInt(computed.getPropertyValue("padding-bottom"));

  height -= subtractors;

  textareaElement.style.height = `${height}px`;
}

function autoSizeTextAreas() {
  let elements = document.querySelectorAll(".js-textarea-autosize");

  Array.prototype.forEach.call(elements, function (element) {
    // Auto size the textarea on page load.
    autoSizeTextArea(element);

    // Auto size the text area when the contents are being changed.
    element.addEventListener("keyup", (e) => autoSizeTextArea(e.target))
  });
}

function convertTimesToLocal() {
  var nodes = document.querySelectorAll("time.js-convert")
  for (var t of nodes) {
    let datetime = new Date(t.getAttribute('datetime'))
    t.textContent = datetime.toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' })
  }
}

document.addEventListener("DOMContentLoaded", function () {
  changeItemDetailsImageOnClick();
  siteSearch();
  openAndCloseFilters();
  toggleSearch();
  openAndCloseMenu();
  lazilyLoadImages();
  scollElementsToBottom();
  autoSizeTextAreas();
  convertTimesToLocal();

  // JS that only runs for mobile.
  if (window.matchMedia("(max-width: 960px)").matches) {
    setHeaderPosition();
  }

});
