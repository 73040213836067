import KidAnalytics from "lib/kid_analytics";

(function() {

  function onFacebookShareClick(e) {
    KidAnalytics.trackContentShared(KidAnalytics.shareType.COLLECTION, KidAnalytics.shareMethod.FACEBOOK);
    FB.ui({
      method: 'share',
      href: window.location.href
    }, function(){});
  }

  function onPinterestShareClick(e) {
    KidAnalytics.trackContentShared(KidAnalytics.shareType.COLLECTION, KidAnalytics.shareMethod.PINTEREST);

    let ogImageTag = document.querySelector('meta[property="og:image"]');
    let ogTitleTag = document.querySelector('meta[property="og:title"]');

    let shareImageUrl = ogImageTag && ogImageTag.getAttribute('content');
    let shareDescription = ogTitleTag && ogTitleTag.getAttribute('content');

    let pinterestShareUrl = "https://www.pinterest.com/pin/create/button/?url=" + encodeURIComponent(window.location.href) + '&media=' + encodeURIComponent(shareImageUrl) + '&description=' + shareDescription;
    window.open(pinterestShareUrl, '_blank');
  }

  function onEmailShareClick(e) {
    KidAnalytics.trackContentShared(KidAnalytics.shareType.COLLECTION, KidAnalytics.shareMethod.EMAIL);
    window.location = 'mailto:?body=' + window.location;
    e.preventDefault();
    return false;
  }

  function onCopyClick(e) {
    KidAnalytics.trackContentShared(KidAnalytics.shareType.COLLECTION, KidAnalytics.shareMethod.COPY);
    // TODO: implement
  }

  let copyShareElements = document.querySelectorAll('.js-share-copy');
  let emailShareElements = document.querySelectorAll('.js-share-email');
  let facebookShareElements = document.querySelectorAll('.js-share-facebook');
  let pinterestShareElements = document.querySelectorAll('.js-share-pinterest');

  for (let el of copyShareElements) { el.addEventListener('click', onCopyClick); }
  for (let el of emailShareElements) { el.addEventListener('click', onEmailShareClick); }
  for (let el of facebookShareElements) { el.addEventListener('click', onFacebookShareClick); }
  for (let el of pinterestShareElements) { el.addEventListener('click', onPinterestShareClick); }

}());
