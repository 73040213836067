import $ from "jquery";

function markAsTrackedIfSuccessful() {
  $('.edit_shipment').click(function(e) {
    $(this).on('ajax:success', function() {
      $(this).children().find('img').removeClass('u-hidden');
    });
  });
}

document.addEventListener("DOMContentLoaded", function () {
  markAsTrackedIfSuccessful();
});
