import KidAnalytics from "lib/kid_analytics";

document.addEventListener("DOMContentLoaded", function() {

  // Track signup through Facebook.
  var facebookButton = document.querySelector(".js-facebook-signup");
  if (facebookButton != null) {
    facebookButton.addEventListener("click", function() {
      KidAnalytics.trackSignUpStarted(KidAnalytics.signUpType.FACEBOOK);
    });
  }

  // Track signup through Google.
  var googleButton = document.querySelector(".js-google-signup");
  if (googleButton != null) {
    googleButton.addEventListener("click", function() {
      KidAnalytics.trackSignUpStarted(KidAnalytics.signUpType.GOOGLE);
    });
  }
  
  // Track signup through Apple.
  var appleButton = document.querySelector(".js-apple-signup");
  if (appleButton != null) {
    appleButton.addEventListener("click", function() {
      KidAnalytics.trackSignUpStarted(KidAnalytics.signUpType.APPLE);
    });
  }

  // Track signup through email.
  var form = document.querySelector(".js-email-signup");
  if (form != null) {
    form.addEventListener("submit", function() {
      KidAnalytics.trackSignUpStarted(KidAnalytics.signUpType.EMAIL);
    });
  }
});
