document.querySelectorAll(".collapsable").forEach(collapsable => {
  let collapsableContents = collapsable.querySelectorAll(".collapsable__content");
  if (collapsableContents.length == 0) return;

  collapsable.querySelectorAll(".collapsable__toggle").forEach(toggle => {
    setupClickListener(collapsable, toggle);
  });
})

function setupClickListener(collapsable, toggle) {
  toggle.addEventListener("click", event => {
    event.preventDefault();

    let expanded = collapsable.classList.contains("collapsable--expanded");

    if (expanded) {
      collapseCollapsable(collapsable, toggle);
      return;
    }

    expandCollapsable(collapsable, toggle);
  });
}

function expandCollapsable(collapsable, toggle) {
  collapsable.classList.add("collapsable--expanded");
  toggle.setAttribute("aria-expanded", true);
}

function collapseCollapsable(collapsable, toggle) {
  collapsable.classList.remove("collapsable--expanded");
  toggle.setAttribute("aria-expanded", false);
}
