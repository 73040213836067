const JsonData = window.JsonDataMock || {}

export function jsonData(key, defaultValue = {}) {
  if (JsonData.hasOwnProperty(key)) {
    return JsonData[key]
  }
  try {
    let elem = document.getElementById("json-data-" + key)
    return JsonData[key] = JSON.parse(elem.textContent)
  } catch (e) {
    console.warn("Could not read jsonData('" + key + "') -", e)
    return defaultValue;
  }
}
