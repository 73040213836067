import OneLinkUrlGenerator from "lib/onelink-smart-script"
import AppDrawer from "components/app-drawer";

/*
 * Sets up a banner at the top of the page and a drawer at the bottom of the page that sends the user to the current
 * version of the page in the app.
 */

function createAppsFlyerOneLink(deeplink) {
  let oneLinkGenerator = new OneLinkUrlGenerator({
    oneLinkURL: "https://kidizen.onelink.me/1644251533/",
    pidStaticValue: 'ss_dflt_pid', // Default pid. "ss" is short for Smart Script.
    campaignStaticValue: 'ss_dflt_cmpgn', // Default campaign. "ss" is short for Smart Script.
    pidKeysList: ['af_pid', 'utm_source'], // Query params to pull a pid from.
    campaignKeysList: ['af_c', 'utm_campaign'] // Query params to pull a campaign from.
  })

  oneLinkGenerator.setDeepLinkValue('deep_link_value', encodeURIComponent(deeplink))

  return oneLinkGenerator.generateUrl()
}

function pageDeeplink() {
  let metaTag = document.querySelector('meta[name="app-deep-link"]')
  if (!metaTag) return null

  return metaTag.getAttribute('content')
}

function setUpAppBanner(link) {
  let appBanner = document.getElementById("app-banner")

  if (!appBanner) return

  if (!shouldShowAppDownload()) {
    appBanner.style.display = "none"
    return
  }

  let cta = appBanner.querySelector(".Button")

  if (!cta) return

  cta.href = link
}

function shouldShowAppDownload() {
  let userAgent = navigator.userAgent
  if (/android/i.test(userAgent)) return true
  if (/iPad|iPhone|iPod/i.test(userAgent)) return true

  return false
}

document.addEventListener('DOMContentLoaded', function () {
  let deeplink = pageDeeplink()

  if (!deeplink) return

  let oneLink = createAppsFlyerOneLink(deeplink)

  setUpAppBanner(oneLink)

  if (shouldShowAppDownload()) new AppDrawer(oneLink).show()
});
