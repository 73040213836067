const UTILS = {

  /*
   * @description - Updates an image element to have a different src attribute.
   * @param imageEl:DOMElement - The image element
   * @param src:String - The new source
   * @return void
   */
  updateImageSource: function (imageEl, src) {
    imageEl.src = src;
  },

  /*
   * @param element:DOMElement
   * @param className:String
   * @return true if element has class className */
  hasClass: function (element, className) {

    if (element && element.classList) {
      return element.classList.contains(className);
    } else {
      return undefined;
    }
  },

  /*
   * @description - Add or remove a class to an element, or elements.
   * @param elements:NodeList or DOMElement
   * @param className:String
   * @param operation:String - Either "add" or "remove"
   * @return void */
  modifyClasses: function (elements, className, operation) {

    if (!!elements.length) { // Is this a node list?
      [].forEach.call(elements, function (element) {
        element.classList[operation](className);
      });
    } else if (!!elements.classList) { // Just a normal node
      elements.classList[operation](className);
    }
  },

  getQueryStringValue: function(key, url) {
    if (!url) url = window.location.href;
    key = key.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + key + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },

  /*
   * @source: https://stackoverflow.com/questions/5999118/add-or-update-query-string-parameter
   * @description: Takes a url and adds a key value as a query param and returns the String
   * @return String
   */
  updateQueryString: function (key, value, url) {

    if (!url) url = window.location.href;
    let re = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi"),
      hash;

    if (re.test(url)) {
      if (typeof value !== 'undefined' && value !== null)
        return url.replace(re, '$1' + key + "=" + value + '$2$3');
      else {
        hash = url.split('#');
        url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
        if (typeof hash[1] !== 'undefined' && hash[1] !== null)
          url += '#' + hash[1];
        return url;
      }
    }
    else {
      if (typeof value !== 'undefined' && value !== null) {
        let separator = url.indexOf('?') !== -1 ? '&' : '?';
        hash = url.split('#');
        url = hash[0] + separator + key + '=' + value;
        if (typeof hash[1] !== 'undefined' && hash[1] !== null)
          url += '#' + hash[1];
        return url;
      }
      else
        return url;
    }
  },

  /**
   * Get the closest matching element up the DOM tree.
   * @private
   * @param  {Element} elem     Starting element
   * @param  {String}  selector Selector to match against
   * @return {Boolean|Element}  Returns null if not match found
   */
  getClosest: function (elem, selector ) {

    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function(s) {
          let matches = (this.document || this.ownerDocument).querySelectorAll(s),
            i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) {
          }
          return i > -1;
        };
    }

    // Get closest match
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }

    return null;
  },

  getURLParams: function () {

    function transformToAssocArray(url) {
      let params = {},
        prmarr = url.split("&");

      for (let i = 0; i < prmarr.length; i++) {
        let tmparr = prmarr[i].split("=");
        params[tmparr[0]] = tmparr[1];
      }

      return params;
    }

    const parameters = window.location.search.substr(1);

    return parameters != null && parameters != "" ? transformToAssocArray(parameters) : {};
  },

  replaceQuotes: function (jsonString) {

    let replacedString = undefined;

    replacedString = jsonString.replace(/&quot;/g, '"');
    replacedString = replacedString.replace(/&#39;/g, "'");

    return replacedString;
  },

  // debouncing function from John Hann
  // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
  debounce: function (func, threshold, execAsap) {
      var timeout;

      return function debounced () {
          var obj = this, args = arguments;
          function delayed () {
              if (!execAsap)
                  func.apply(obj, args);
              timeout = null;
          };

          if (timeout)
              clearTimeout(timeout);
          else if (execAsap)
              func.apply(obj, args);

          timeout = setTimeout(delayed, threshold || 100);
      };
  },

  /*
   * @description: Get the absolute height of an element including margin;
   * @return Number
   */
  getAbsoluteHeight(el) {
    // Get the DOM Node if you pass in a string
    el = (typeof el === 'string') ? document.querySelector(el) : el;

    var styles = window.getComputedStyle(el);
    var margin = parseFloat(styles['marginTop']) +
                 parseFloat(styles['marginBottom']);
    return Math.ceil(el.offsetHeight + margin);

  }
};

export default UTILS;
