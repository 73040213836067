/* eslint no-console:0 */

import jQuery from 'jquery'
import jqueryUjsInit from 'jquery-ujs'

import {} from "components/app-deep-link";
import {} from "lib/cart";
import {} from "lib/collapsable";
import {} from "lib/form";
import {} from "lib/kidizen_ui";
import {} from "lib/collections";
import {} from "lib/share_utils";
import {} from "lib/tracking";

import {} from "web/sign_in_sign_up";
import {} from "web/messages";
import {} from "web/profile_menu";
import {} from "web/earnings";

import KidAnalytics from "lib/kid_analytics";

// Make KidAnalytics globally accessible. There are erb files that call this directly.
window.KidAnalytics = KidAnalytics;

// Initialize jQuery UJS
jqueryUjsInit(jQuery)
