import AJAX from "lib/web_utils";
import $ from "jquery";

function refreshEarnings() {

  let list = getListPriceInput();
  let current_list_value = list.value

  AJAX.get("/earnings?&item_price_cents=" + current_list_value).then(function (response) {
    return response.json();
  }).then(function (item) {
    setEarningsAmount(item.earnings);
  });
}

function setEarningsAmount(earnings) {
  let itemEarningsElement = document.querySelector('.js-earnings');
  if (itemEarningsElement) {
    itemEarningsElement.value = earnings;
  }
}

function checkForItemPriceChange() {

  let list = getListPriceInput();

  if (list) {
    $(list).change(refreshEarnings);
  }
}

function getListPriceInput() {
  return document.getElementById('item_list_price');
}

document.addEventListener("DOMContentLoaded", function () {
  checkForItemPriceChange();
});
