document.addEventListener("DOMContentLoaded", () => {
  let input = document.getElementById("message_image");
  let imagePreviewContainer = document.getElementById("message-image-preview");

  if (input === null || imagePreviewContainer === null) return;

  setupInputListener(input, imagePreviewContainer);
  setupRemoveImageListener(input, imagePreviewContainer);
});

function setupInputListener(input, imagePreviewContainer) {
  let childImages = imagePreviewContainer.getElementsByTagName("img");

  if (childImages.length === 0) return;

  let imagePreview = childImages[0];

  input.addEventListener("change", event => {
    let files = event.target.files;

    if (event.target.files.length === 0) return;

    let file = files[0];

    if (!file.type.startsWith("image/")) return;

    let reader = new FileReader();

    reader.onload = event => {
      imagePreview.src = event.target.result;
      imagePreviewContainer.classList.add("isLoaded");
    };

    reader.readAsDataURL(file);
  });

}

function setupRemoveImageListener(input, imagePreviewContainer) {
  let removeImage = imagePreviewContainer.getElementsByClassName("Remove");

  if (removeImage.length === 0) return;

  removeImage[0].addEventListener("click", () => {
    imagePreviewContainer.classList.remove("isLoaded");
    input.value = "";
  });
}
